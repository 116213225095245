const applicationType = process.env.REACT_APP_APPLICATIONTYPE;

exports.API = getApis(applicationType);

function getApis(applicationType) {
  let url = '';
  let clientUrl = '';
  if (applicationType === "local") {
    url = "http://localhost:8989/";
    clientUrl = "http://localhost:3000/";
  }
  else if (applicationType === "local_lighting") {
    url = "http://localhost:8999/";
    clientUrl = "http://localhost:3000/";
  }
  else if (applicationType === "staging") {
    url = "https://keiservice.sastabuy.in/api/";
    clientUrl = "https://keiservice.sastabuy.in/"
  }
  else if (applicationType === "staging_lighting") {
    url = "https://keilighting.sastabuy.in/api/";
    clientUrl = "https://keilighting.sastabuy.in/"
  }
  else if (applicationType === "production") {
    url = "https://keihifiservice.com/api/";
    clientUrl = "https://keihifiservice.com/";
  }
  else if (applicationType === "production_lighting") {
    url = "https://keihifilighting.com/api/";
    clientUrl = "https://keihifilighting.com/";
  }
  return {
    APPLICATION_URL: url,
    JOBSHEET_URL: url + "jobsheets",
    APP_USERS: url + "login",
    APP_USERURL: url + "users",
    APP_USER_PAGINATION: url + "users/user-pagination",
    APP_USERSLOGOUT: url + "logout",
    APP_USERSCHECKAUTH: url + "checkAuthenticated",
    APP_PRODUCTS: url + "products",
    JOBSHEET_IMAGE_UPLOAD: url + "upload",
    JOBSHEET_PAGINATON: url + "jobsheetsPagination",
    REACT_APP_USERSCHECKAUTH: url + "checkAuthenticated",
    REACT_APP_USERS: url + "login",
    REACT_APP_USERSLOGOUT: url + "logout",
    REACT_APP_VIEW_JOBSHEET: clientUrl+"viewJobsheet",
    REACT_APP_IMAGES_URL: clientUrl+"/public/uploads",
    REACT_APP_USERS_LIST: url+"users",
    APP_PRODUCT: url+"product",
    APP_PRODUCT_PAGINATION: url+"productsPagination",
    APP_PRODUCT_SERIALS: url+"serials",
    APP_FAILED_SERIAL_PAGINATION: url+"failedSerialPagination",
    INVENTORY_URL: url + "inventory",
    INVENTORY_PAGINATION: url + "inventoryPagination",
    WARRANTY: url + "warranty",
    WARRANTY_PAGINATION: url + "warranty/warranty-pagination",
    WARRANTY_PRODUCT: url + "warranty-products",
    CHALLAN_URL: url + "challans",
    CHALLAN_PAGINATION: url + "challans/challans-pagination",
    INVENTORY_TYPE: url + "inventoryType",
    INVENTORY_QUANTITY_CHECK: url + "inventoryCheckQuantity"
  }
}