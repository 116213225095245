import React, {
    useState,
    useReducer,
    useEffect,
    useContext,
} from "react";

import { UserContext } from "../contexts/userContext";
import application from "../constants/apiList";
import Loader from "./loader";
import swal from "@sweetalert/with-react";
import moment from 'moment'
import axios from "axios";
import { Col, Row, Card, Jumbotron, Button, Form, Collapse } from "react-bootstrap";
import "../styles/pages.scss";
import "../styles/componentsStyles/addJobsheet.scss";
import ImageRenderer from "./imageRenderer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
var $ = require("jquery");

const initialState = {
    warranty_fname: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_mname: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_sname: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_email_id: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_mobile_no: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_state: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_city: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_address: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_brand: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_product_category: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_dname: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_invoice_no: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_purchase_date: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_product_serial_no: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_model: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    warranty_upload_invoice: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
}

function init(initialData) {
    return {
        data: initialData,
    };
}


let productData;
const WarrantyForm = () => {
    const [userState, setUserState] = useContext(UserContext);
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [validated, setValidated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    
    const [brandState, setBrandState] = useState(null);
    const [categoryState, setCategoryState] = useState(null);
    const [productState, setProductState] = useState(null);
    const [isInitDataLoadStarted, setIsInitDataLoadStarted] = useState(false);
    const [uploadInvoiceFile, setUploadInvoiceFile] = useState(null);
    const [uploadInvoiceFileProgress, setUploadInvoiceFileProgress] = useState(null);
    const [uploadInvoiceSource, setUploadInvoiceSource] = useState(null);
    const [invoiceCollapse, setInvoiceCollapse] = useState(false);

    let keyCount = 0;

    useEffect(() => {
        if(!isInitDataLoadStarted){
          // console.log("Logs:- useEffect => ")
          getInitData();
          setIsInitDataLoadStarted(true);
          }
      }, []);

    const getInitData = () => {
          getProductData("", "");
      }
    const getProductData = async () => {
        try{
            const config = {
                method: "get",
                url: application.API.WARRANTY_PRODUCT,
                withCredentials: true,
            };
            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                
                productData = response.data.result;
                console.log("api productData ==> ",productData);
                let brand = Object.keys(response.data.result);
                // let category = Object.keys(productData[brand[0]]);
                // let productList;
                
                setBrandState(Object.keys(response.data.result));
                // setCategoryState(category);
                // setProductState(productList);
                setIsLoaded(true);
            }else {
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
        }catch (error) {
            console.log("error in getProductData :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" }).then(() => {
                setIsLoaded(true);
            });
          }
    }

    const changeCategory = (e) => {
        const selectedBrand = e.target.value;
        const productList = [];
        let categoryList;
        console.log("productData ==> ",productData);

        if (selectedBrand === "") {
            categoryList = [];
        } else {
            categoryList = Object.keys(productData[selectedBrand]);
            console.log("categoryList ===> ",categoryList);
        }
        setCategoryState(categoryList);
        setProductState(productList);
    }

    const changeProduct = (e) => {
        const selectedCategory = e.target.value;
        const brand = state.data.warranty_brand.value;
        const productList = productData[brand][selectedCategory];
        setProductState(productList);
    }

    function reducer(state, action) {
        switch (action.type) {
            case "valueChanged":
                let stateData = {};
                let changedValue = {};
                changedValue["value"] = action.payload;
                changedValue["isValid"] = true;
                changedValue["errorMsg"] = "";
    
                state.data[action.key] = changedValue;
                stateData["data"] = state.data;
    
                return stateData;
    
            case "initialEditState":
                let initialEditState = {};
                initialEditState['data'] = action.payload;
                return initialEditState;
    
            default:
                throw new Error();
        }
    }

    const resetFormState = () => {
        for (let key in initialState) {
            let defaultValue = {};
            defaultValue['isValid'] = true;
            defaultValue['value'] = "";
            defaultValue['errorMsg'] = "";
            initialState[key] = defaultValue;
        }
        return initialState;
    }

    const resetForm = () => {
        resetFormState();
        dispatch({
            type: "initialEditState",
            key: '',
            payload: initialState,
        })
        setValidated(false);
    }

    const getKey = () => {
        return keyCount++;
    }

    const getFileSize = (sizeInBytes) => {
        return sizeInBytes/1024/1024;
    }

    const checkFileExtension = (fileName) => {
        return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName)[0] : fileName;
    }

    const validateInvoice = (e) => {
        // console.log("imageName :- ", e.target.files[0].name);
        const file = e.target.files[0];
        if(getFileSize(file.size) > 2){
          swal({
            title: "Error",
            text: "The size of the upload should be less than 2mb",
            icon: "error",
          });
          e.target.value = "";
        }
        else if(checkFileExtension(file.name).toLowerCase() !== "pdf"){
            swal({
                title: "Error",
                text: "Only pdf files are allowed",
                icon: "error",
            });
            e.target.value = "";
        }
        setUploadInvoiceFile(e.target.files[0]);
    };

    const getFileName = (file) => {
        if (file !== null) {
          const fileName = file.split("/")[file.split("/").length - 1];
          return fileName
        }
        return null;
    };

    const uploadFile = async (e) => {
        let form = new FormData();
        form.append("file", uploadInvoiceFile);
        const response = await axios.post(application.API.JOBSHEET_IMAGE_UPLOAD, form, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                const progressData = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadInvoiceFileProgress(progressData);
            }
        });
        if (response.status === 200) {
            if (response.data["status"] === "success") {
                const imgSrc =
                response.data.data["filePath"] +
                "/" +
                response.data.data["fileName"];
                setUploadInvoiceSource(imgSrc);
            }
        }
    }

    const updateInsertUserData = async () => {
        setIsLoaded(true);
        try {
            const userData = {};
            //set object keys value from the state
            Object.keys(state.data).map((stateDataKeyObj)=>{
                userData[stateDataKeyObj] = state.data[stateDataKeyObj].value
            })
            userData.warranty_submission_date = moment();
            userData.warranty_upload_invoice = getFileName(uploadInvoiceSource);
            userData.warranty_approval = 0;
            userData.warranty_dname = userData.warranty_dname.trim();
            let config = {}
            config = {
                url: application.API.WARRANTY,
                method: "post",
                withCredentials: true,
                data: userData
            };
            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                let responseText = config.method === "post" ? "Warranty added" : "Warranty updated"
                swal({ title: "Success", text: responseText, icon: "success" }).then((value) => {
                    resetForm()
                });;
            }
        }catch(e){
            setIsLoaded(false);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            console.log("Error", e)
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        setIsLoaded(true);
        if (form.checkValidity()) {
            event.preventDefault();
            updateInsertUserData()
        }
        else {
            event.preventDefault();
            event.stopPropagation();
            var errorElements = document.querySelectorAll(
                "input.form-control:invalid");
            $('html, body').animate({
                scrollTop: $(errorElements[0]).offset().top - 20
            }, 1000);
            swal({ title: "Error", text: "Please fix the errors in your form", icon: "warning" })
        }
    }

    if (brandState === null || categoryState === null || productState === null) {
        if(userState.loggedInStatus && !isInitDataLoadStarted){
          // console.log("Logs:- isInitDataLoadStarted ====> ")
          getInitData();
          setIsInitDataLoadStarted(true);
        }
        // return <Loader />;
    }

    if(!isLoaded || brandState === null){
        console.log("Loading init data ",isLoaded);
        console.log("Loading brandState ",brandState);
        return <Loader />;
    }

    return (
        <>
            <Jumbotron className="jumbotron-title shadow-sm">
                <h4 style={{ textAlign: "center" }}>Register Warranty</h4>
            </Jumbotron>
            <Card className="main-container">
                <Card.Body className="main-container-body">
                    <div className="warranty-registration-container">
                        <div className="form-container">
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="warranty_fname">
                                        <Form.Label>First Name <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            required={(state.data.warranty_fname.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_fname",
                                                    payload: event.target.value.replace(/\s/g,''),
                                                });
                                            }}
                                            value={state.data.warranty_fname.value}
                                            placeholder="Enter First Name"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={4} controlId="warranty_mname">
                                        <Form.Label>Middle Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_mname",
                                                    payload: event.target.value.replace(/\s/g,''),
                                                });
                                            }}
                                            value={state.data.warranty_mname.value}
                                            placeholder="Enter Middle Name"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={4} controlId="warranty_sname">
                                        <Form.Label>Last Name <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            required={(state.data.warranty_sname.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_sname",
                                                    payload: event.target.value.replace(/\s/g,''),
                                                });
                                            }}
                                            value={state.data.warranty_sname.value}
                                            placeholder="Enter Last Name"
                                        />
                                    </Form.Group>
                                </Form.Row>
                                
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="warranty_email_id">
                                        <Form.Label>Email ID <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            required={(state.data.warranty_email_id.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_email_id",
                                                    payload: event.target.value.replace(/\s/g,''),
                                                });
                                            }}
                                            value={state.data.warranty_email_id.value}
                                            placeholder="Enter Email Id"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={4} controlId="warranty_mobile_no">
                                        <Form.Label>Mobile No. <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            required={(state.data.warranty_mobile_no.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_mobile_no",
                                                    payload: event.target.value.replace(/\s/g,''),
                                                });
                                            }}
                                            value={state.data.warranty_mobile_no.value}
                                            placeholder="Enter Mobile No."
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={4} controlId="warranty_state">
                                        <Form.Label>State  <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            required={(state.data.warranty_state.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_state",
                                                    payload: event.target.value.replace(/\s/g,''),
                                                });
                                            }}
                                            value={state.data.warranty_state.value}
                                            placeholder="State"
                                            required
                                        />
                                    </Form.Group>
                                </Form.Row> 
                                
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="warranty_city">
                                        <Form.Label>City <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            required={(state.data.warranty_city.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_city",
                                                    payload: event.target.value.replace(/\s/g,''),
                                                });
                                            }}
                                            value={state.data.warranty_city.value}
                                            placeholder="Enter City"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={4} controlId="warranty_address">
                                        <Form.Label>Address <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            autoComplete="off"
                                            required={(state.data.warranty_address.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_address",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.warranty_address.value}
                                            placeholder="Enter Address"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={4} controlId="warranty_dname">
                                        <Form.Label>Dealer Name <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            required={(state.data.warranty_dname.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_dname",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.warranty_dname.value}
                                            placeholder="Enter Dealer Name"
                                        />
                                    </Form.Group>
                                </Form.Row> 
                                
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="brand">
                                        <Form.Label>Brand <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            required={(state.data.warranty_brand.value !== "" ? false : true)}
                                            as='select'
                                            value={state.data.warranty_brand.value}
                                            onChange={(event) => {
                                                changeCategory(event);
                                                dispatch({
                                                type: "valueChanged",
                                                key: "warranty_brand",
                                                payload: event.target.value,
                                                })
                                            }}
                                        >
                                        <option value=''>Select Brand</option>
                                        {
                                            brandState.map((brand,index) => {
                                                return (
                                                    <option key={brand.toString() + "-" + index.toString()} value={brand}>{brand}</option>
                                                )
                                            })
                                        }
                                        </Form.Control>
                                    </Form.Group>
                                    
                                    <Form.Group as={Col} sm={4} controlId="category">
                                        <Form.Label>Category <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            required={(state.data.warranty_product_category.value !== "" ? false : true)}
                                            as='select'
                                            value={state.data.warranty_product_category.value}
                                            onChange={(event) => {
                                                changeProduct(event);
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_product_category",
                                                    payload: event.target.value,
                                                })
                                            }}
                                        >
                                            <option value=''>Select Category</option>
                                            {(categoryState != null) 
                                                ? categoryState.map((category) => {
                                                    return (
                                                    <option key={category + "-" + getKey()} value={category}>{category}</option>
                                                    )
                                                })
                                                : null}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="model">
                                        <Form.Label>Model <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            required={(state.data.warranty_model.value !== "" ? false : true)}
                                            as='select'
                                            value={state.data.warranty_model.value}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_model",
                                                    payload: event.target.value,
                                                })
                                            }}
                                        >
                                            <option value=''>Select Model</option>
                                            {(productState != null) 
                                            ? productState.map((model) => {
                                                return (
                                                <option key={model + "-" + getKey()} value={model}>{model}</option>
                                                )
                                            })
                                            : null}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="warranty_product_serial_no">
                                        <Form.Label>Product Serial No. <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            required={(state.data.warranty_product_serial_no.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_product_serial_no",
                                                    payload: event.target.value.replace(/\s/g,''),
                                                });
                                            }}
                                            value={state.data.warranty_product_serial_no.value}
                                            placeholder="Product Serial No."
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={4} controlId="warranty_invoice_no">
                                        <Form.Label>Invoice No. <span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            required={(state.data.warranty_invoice_no.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_invoice_no",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.warranty_invoice_no.value}
                                            placeholder="Invoice No."
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={4} controlId="warranty_purchase_date">
                                        <Form.Label>Purchase Date<span className="important">*</span></Form.Label>
                                        <Form.Control
                                            type="date"
                                            required={(state.data.warranty_purchase_date.value !== "") ? false : true}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "warranty_purchase_date",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.warranty_purchase_date.value}
                                            placeholder="Purchase Date"
                                            required
                                        />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="uploadInvoice">
                                        <Form.Label>Upload Invoice (2mb) <span className="important">*</span></Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="file"
                                                    onChange={validateInvoice}
                                                    required={true}
                                                />
                                            </Col>
                                            <Col
                                                md={4}
                                                style={{ 'marginTop': '-5px' }}
                                            >
                                                <Button
                                                    type="button"
                                                    onClick={() => uploadFile(this)}
                                                    disabled={uploadInvoiceFile === null}
                                                >
                                                    Upload
                                                </Button>
                                            </Col>
                                        </Row>
                                        {uploadInvoiceFileProgress === 100 ? <Row><Col style={{textAlign: "center"}}>
                                            <FontAwesomeIcon icon={invoiceCollapse ? faChevronUp : faChevronDown} onClick={() => setInvoiceCollapse(!invoiceCollapse)} />
                                            <Collapse in={invoiceCollapse}>
                                            <div>
                                                <ImageRenderer
                                                imageSource={uploadInvoiceSource}
                                                uploadProgress={uploadInvoiceFileProgress}
                                                />
                                            </div>
                                            </Collapse></Col></Row> : ''}
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Button type="submit">Submit form</Button>
                                    <Button style={{ 'marginLeft': '45px' }} onClick={resetForm}>Reset form</Button>
                                </Form.Row>
                            </Form> 
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default WarrantyForm