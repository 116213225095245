import React, { Component } from 'react';
import { Col, Card, Form, Jumbotron, Row, Button } from "react-bootstrap";
import logo from "../resources/images/KEI-logo.png";
import swal from "@sweetalert/with-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faPrint} from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import "../styles/pages.scss";
import "../styles/componentsStyles/challan.scss";
import Loader from "./loader";
import application from "../constants/apiList";
import moment from 'moment'
import html2canvas from "html2canvas";
import { jsPDF } from 'jspdf';
import { UserContext } from "../contexts/userContext";
import { Redirect } from "react-router-dom";
var $ = require("jquery");
const _ = require('lodash');


// application constants
const defaultItemObject = {
    id: {
        value: null
    },
    challan_desc: {
        value: ""
    },
    challan_sac: {
        value: ""
    },
    challan_quantity: {
        value: 0
    },
    challan_rate: {
        value: 0
    },
    challan_amount: {
        value: 0
    },
}
const initialData = {
    to_company_name: {
        value: ""
    },
    to_company_add: {
        value: ""
    },
    to_company_contact: {
        value: ""
    },
    to_company_gst: {
        value: ""
    },
    to_company_pan: {
        value: ""
    },
    challan_date: {
        value: ""
    },
    challan_item_rows: [
        defaultItemObject
    ]
}

class Challan extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            loggedUser: null,
            formData: initialData,
            validated: false,
            loader: false,
            challanId: null,
            userData: null
        }
    }
    componentDidMount() {
        const {
            match
        } = this.props
        this.setState({
          userData: this.context[0]
        })
        const userData = this.context[0];
        console.log("userData",this.context)
        if((userData['loggedInStatus'])){
            this.setState({
                loggedUser: userData['user'],
            })
        }
        if(match.path.includes('edit-challan'))
        {
            this.setState({
                challanId: match.params.challanId
            })
            this.getChallanData(match.params.challanId)
        }
    }

    // api methods
    getChallanData = async (challanId) => {
        this.setLoaderState(true)
        try {
            const config = {
                url: application.API.CHALLAN_URL + "/" + challanId,
                type: "GET",
                withCredentials: true,
            };
            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                let challanData = response.data.data
                let modifiedInitData = _.cloneDeep(initialData)
                Object.keys(initialData).map((key)=>{
                    modifiedInitData[key].value = challanData[key]
                })

                // loop through challan info to set item info
                let infoArray = []
                challanData['challan_info'].map((challanInfoObj)=>{
                    // form obj keys values
                    let modifiedDefaultItemObj = _.cloneDeep(defaultItemObject)
                    Object.keys(defaultItemObject).map((key)=>{
                        modifiedDefaultItemObj[key].value = challanInfoObj[key]
                    })
                    infoArray.push(modifiedDefaultItemObj)
                })
                // store the formed array
                modifiedInitData['challan_item_rows'] = infoArray
                // convert challan date into suitable date format
                modifiedInitData['challan_date'].value = moment(modifiedInitData['challan_date'].value).format("YYYY-MM-DD")
                


                this.setState({
                    formData: modifiedInitData
                })
            }
            else {
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
            this.setLoaderState(false)
        }
        catch(e)
        {
            this.setLoaderState(false)
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            console.log("Error", e)
        }
    }

    insertUpdateChallanData = async () => {
        const {
            formData,
            challanId,
            loggedUser
        } = this.state
        let reqData = {}
        Object.keys(initialData).map((key)=>{
            reqData[key] = formData[key].value
        })
        let infoArr = []
        formData.challan_item_rows.map((challanItem)=>{
            let modifiedObj = {}
            Object.keys(defaultItemObject).map((key)=>{
                modifiedObj[key] = challanItem[key].value
            })
            infoArr.push(modifiedObj)
        })
        reqData.challan_item_rows = infoArr
        console.log("loggedUser",loggedUser)
        reqData.challan_user = loggedUser.userName
        let config = {}
        if(challanId===null)
        {
            reqData.challan_item_rows.map((challanObj)=>{
                delete challanObj.id
            })
            config = {
                url: application.API.CHALLAN_URL,
                method: "POST",
                withCredentials: true,
                data: reqData
            };
        }
        else
        {
            config = {
                url: application.API.CHALLAN_URL+"/"+challanId,
                method: "PATCH",
                withCredentials: true,
                data: reqData
            };
        }
        console.log("reqData",reqData)
        const response = await axios(config);
        if (response.status === 200 && response.data.status === "success") {
            let responseText = config.method === "POST" ? "Challan added" : "Challan updated"
            swal({ title: "Success", text: responseText, icon: "success" }).then((value) => {
                window.location.href = "/"
              });
        }
        else {
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
        this.setLoaderState(false);
    }

    // general methods
    setLoaderState = (value) => {
        this.setState({
            loader:value
        })
    }

    modifyFormData = (identifier,value) => {
        const {
            formData
        } = this.state
        let modifiedFormData = _.cloneDeep(formData)
        modifiedFormData[identifier].value = value
        this.setState({
            formData: modifiedFormData
        })
    }

    modifyItemData = (identifier,itemIndex,value) => {
        const {
            formData
        } = this.state
        let modifiedFormData = _.cloneDeep(formData)
        modifiedFormData.challan_item_rows[itemIndex][identifier].value = value
        this.setState({
            formData: modifiedFormData
        })
    }

    addChallanDefaultObject = () => {
        const {
            formData
        } = this.state
        let modifiedFormData = _.cloneDeep(formData)
        modifiedFormData.challan_item_rows.push(defaultItemObject)
        this.setState({
            formData: modifiedFormData
        })
    }

    deleteChallanObj = (itemIndex) => {
        const {
            formData
        } = this.state
        let modifiedFormData = _.cloneDeep(formData)
        modifiedFormData.challan_item_rows.splice(itemIndex,1)
        this.setState({
            formData: modifiedFormData
        })
    }

    getTotalAmount = () => {
        const {
            formData
        } = this.state

        let sum = 0;
        formData.challan_item_rows.map((itemObj)=>{
            sum += itemObj.challan_rate.value * itemObj.challan_quantity.value
        })
        return sum
    }

    resetFormState = () => {
        this.setState({
            formData: initialData
        })
    }

    setValidatedState = (value) => {
        this.setState({
            validated: value
        })
    }

    handleSubmit = (event) => {
        const {
            challanId
        } = this.state
        const form = event.currentTarget;
        this.setValidatedState(true)

        if(form.checkValidity())
        {
            event.preventDefault();
            this.setLoaderState(true)
            this.insertUpdateChallanData()
        }
        else {
            event.preventDefault();
            event.stopPropagation();
            var errorElements = document.querySelectorAll(
                "input.form-control:invalid");
            $('html, body').animate({
                scrollTop: $(errorElements[0]).offset().top - 20
            }, 1000);
            swal({ title: "Error", text: "Please fix the errors in your form", icon: "warning" })
        }
    }

     getHeight = (element) =>
    {
        element.style.visibility = "hidden";
        document.body.appendChild(element);
        var height = element.offsetHeight + 0;
        document.body.removeChild(element);
        element.style.visibility = "visible";
        return height;
    }

    downloadForm = () => {
        this.setLoaderState(true)
        $('#main-container-body').show();
        $('#main-container-body-challan').hide();
        const input = document.getElementById("main-container-body")
        console.log("test",input)
        html2canvas(input, {
            height: input.offsetHeight,
            windowHeight: input.offsetHeight,
            useCORS: true
            }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            let pdf = new jsPDF("p", "mm", "a4")
            let width = pdf.internal.pageSize.getWidth();
            let height = pdf.internal.pageSize.getHeight();
            const imgProps= pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight, "", "FAST");
            // pdf.save(name);
            pdf.output('dataurlnewwindow');
            // pdf.addJs('print({});')
            // pdf.autoPrint()
            this.setLoaderState(false)
            })
        
        $('#main-container-body-challan').show();
        $('#main-container-body').hide();
      }

    // render methods
    renderGeneralForm = () => {
        const {
            formData
        } = this.state
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col} sm={4} controlId="consignerName">
                        <Form.Label>Consigner Name <span className="important">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(event) => {
                                //   call general method modifyFormData(identifier,value)
                                this.modifyFormData("to_company_name",event.target.value)
                            }}
                            value={formData.to_company_name.value}
                            placeholder="Enter Consigner Name"
                            required
                        />
                    </Form.Group>

                    <Form.Group as={Col} sm={4} controlId="consignerAdd">
                        <Form.Label>Consigner Address <span className="important">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(event) => {
                                //   call general method modifyFormData(identifier,value)
                                this.modifyFormData("to_company_add",event.target.value)
                            }}
                            value={formData.to_company_add.value}
                            placeholder="Enter Consigner Address"
                            required
                        />
                    </Form.Group>

                    <Form.Group as={Col} sm={4} controlId="consignerContact">
                        <Form.Label>Consigner Contact <span className="important">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(event) => {
                                //   call general method modifyFormData(identifier,value)
                                this.modifyFormData("to_company_contact",event.target.value)
                            }}
                            value={formData.to_company_contact.value}
                            placeholder="Enter Consigner Address"
                            required
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} sm={4} controlId="consignerGSTIN">
                        <Form.Label>Consigner GSTIN</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(event) => {
                                //   call general method modifyFormData(identifier,value)
                                this.modifyFormData("to_company_gst",event.target.value)
                            }}
                            value={formData.to_company_gst.value}
                            placeholder="Enter Consigner GSTIN"
                        />
                    </Form.Group>

                    <Form.Group as={Col} sm={4} controlId="consignerPAN">
                        <Form.Label>Consigner PAN</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(event) => {
                                //   call general method modifyFormData(identifier,value)
                                this.modifyFormData("to_company_pan",event.target.value)
                            }}
                            value={formData.to_company_pan.value}
                            placeholder="Enter Consigner Address"
                        />
                    </Form.Group>

                    <Form.Group as={Col} sm={4} controlId="challanDate">
                        <Form.Label>Challan Date <span className="important">*</span></Form.Label>
                        <Form.Control
                            type="date"
                            onChange={(event) => {
                                //   call general method modifyFormData(identifier,value)
                                this.modifyFormData("challan_date",event.target.value)
                            }}
                            value={formData.challan_date.value}
                            placeholder="Enter Consigner Address"
                            required
                        />
                    </Form.Group>
                </Form.Row>
            </>
        )
    }

    renderItemsArray = () => {
        const {
            formData
        } = this.state
        return (
            <>
                <hr />

                {/* header row */}
                <Form.Row>
                    <Form.Group as={Col} sm={1} controlId="itemSrHeader">
                        <Form.Label>#</Form.Label>
                    </Form.Group>

                    <Form.Group as={Col} sm={3} controlId="itemDescHeader">
                        <Form.Label>Item and Description</Form.Label>
                    </Form.Group>

                    <Form.Group as={Col} sm={3} controlId="itemHSNHeader">
                        <Form.Label>HSN/Serial No.</Form.Label>
                    </Form.Group>

                    <Form.Group as={Col} sm={1} controlId="itemQuantityHeader">
                        <Form.Label>Quantity</Form.Label>
                    </Form.Group>

                    <Form.Group as={Col} sm={1} controlId="itemRateHeader">
                        <Form.Label>Rate</Form.Label>
                    </Form.Group>

                    <Form.Group as={Col} sm={2} controlId="itemAmountHeader">
                        <Form.Label>Amount</Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} sm={1} controlId="itemActionHeader">
                        <Form.Label>Action</Form.Label>
                    </Form.Group>
                </Form.Row>

                {
                    formData.challan_item_rows.map((itemObj,itemIndex) => {
                        return (
                            <Form.Row key={itemIndex.toString()+"itemDivs"}>
                                <Form.Group as={Col} sm={1} controlId="itemSrDiv" className="my-auto">
                                    <Form.Label className="my-auto">{itemIndex+1}</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} sm={3} controlId="itemDescDiv" className="my-auto">
                                    <Form.Control
                                        type="text"
                                        onChange={(event) => {
                                            //   call general method modifyItemData(identifier,value)
                                            this.modifyItemData("challan_desc",itemIndex,event.target.value)
                                        }}
                                        value={formData.challan_item_rows[itemIndex].challan_desc.value}
                                        placeholder="Item Description"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group as={Col} sm={3} controlId="itemHSNDiv" className="my-auto">
                                    <Form.Control
                                            type="text"
                                            onChange={(event) => {
                                                //   call general method modifyItemData(identifier,value)
                                                this.modifyItemData("challan_sac",itemIndex,event.target.value)
                                            }}
                                            value={formData.challan_item_rows[itemIndex].challan_sac.value}
                                            placeholder="Item HSN/Serial No."
                                            required
                                    />
                                </Form.Group>

                                <Form.Group as={Col} sm={1} controlId="itemQuantityDiv" className="my-auto">
                                    <Form.Control
                                            type="number"
                                            onChange={(event) => {
                                                //   call general method modifyItemData(identifier,value)
                                                this.modifyItemData("challan_quantity",itemIndex,event.target.value)
                                            }}
                                            value={formData.challan_item_rows[itemIndex].challan_quantity.value}
                                            placeholder="Quantity"
                                            min='0'
                                            required
                                    />
                                </Form.Group>

                                <Form.Group as={Col} sm={1} controlId="itemRateDiv" className="my-auto">
                                <Form.Control
                                        type="number"
                                        onChange={(event) => {
                                            //   call general method modifyItemData(identifier,value)
                                            this.modifyItemData("challan_rate",itemIndex,event.target.value)
                                        }}
                                        value={formData.challan_item_rows[itemIndex].challan_rate.value}
                                        placeholder="₹"
                                        min='0'
                                        required
                                />
                                </Form.Group>

                                <Form.Group as={Col} sm={2} controlId="itemAmountDiv" className="my-auto" className="my-auto">
                                    <Form.Label className="my-auto">₹ {formData.challan_item_rows[itemIndex].challan_quantity.value * formData.challan_item_rows[itemIndex].challan_rate.value}</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} sm={1} controlId="itemActionDiv" className="my-auto">
                                    {
                                        formData.challan_item_rows.length>1?
                                        (
                                            <FontAwesomeIcon onClick={()=>this.deleteChallanObj(itemIndex)} icon={faTrash}/>
                                        )
                                        :
                                        null
                                    }
                                </Form.Group>
                            </Form.Row>
                        )
                    })
                }

                {/* footer buttons */}
                <Form.Row>
                    <Form.Group as={Col} sm={9} controlId="itemTotalTextFooter">
                        <Form.Label><b>Total</b></Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} sm={2} controlId="itemTotalFooter">
                        <Form.Label className="my-auto"><b>₹ {this.getTotalAmount()}</b></Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} sm={1} controlId="itemAddFooter">
                        <FontAwesomeIcon onClick={()=>this.addChallanDefaultObject()} icon={faPlus}/>
                    </Form.Group>
                </Form.Row>

                <hr />


            </>
        )
    }

    submitResetColumns = () => {
        return (
            <Form.Row>
                <Button type="submit">Submit form</Button>
                <Button style={{ 'marginLeft': '45px' }} onClick={this.resetFormState}>Reset form</Button>
            </Form.Row>
        )
    }

    renderPrintDiv = () => {
        const {
            formData,
            loggedUser
        } = this.state
        return (
            <Card.Body id="main-container-body" style={{ display: "none" }} className="main-container-body print-challan-div">
                <div className="d-flex justify-content-between mb-1">
                    <div>
                        <img src={logo} alt="logo" style={{ width: 100 }} />
                    </div>
                    <div>
                        <h1>
                            Delivery Challan
                        </h1>
                    </div>
                </div>

                <div className="text-left mb-3">
                    <h4>Kripa Electronics (India) Pvt. Ltd.</h4>
                    <h5 className="challan-company-address">{loggedUser?.companyAdd}</h5>
                    <h4>GSTIN: {loggedUser?.companyGst}</h4>
                </div>

                <div className="d-flex justify-content-between">
                    <div className="text-left">
                        <h4>Consigner:</h4>
                        <h4>{formData.to_company_name.value}</h4>
                        <div className="challan-consigner-address">
                            {formData.to_company_add.value}
                        </div>
                        <div>
                            {formData.to_company_contact.value}
                        </div>
                        <div>
                            GSTIN: {formData.to_company_gst.value}
                        </div>
                        <div>
                            PAN: {formData.to_company_pan.value}
                        </div>
                    </div>

                    <div className="mt-auto">
                        <b>Challan Date: {formData.challan_date.value}</b>
                    </div>
                </div>

                <hr />

                <div className="mt-2 d-flex justify-content-center">
                    <table className="custom-challan-table w-75">
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Item and Description
                                </th>
                                <th>
                                    HSN/Serial No.
                                </th>
                                <th>
                                    Quantity
                                </th>
                                <th>
                                    Rate
                                </th>
                                <th>
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                formData.challan_item_rows.map((challanItem,itemIndex)=>{
                                    return (
                                        <tr key={itemIndex.toString()+"printChallanRows"}>
                                            <td>
                                                {itemIndex+1}
                                            </td>
                                            <td>
                                                {challanItem.challan_desc.value}
                                            </td>
                                            <td>
                                                {challanItem.challan_sac.value}
                                            </td>
                                            <td>
                                                {challanItem.challan_quantity.value}
                                            </td>
                                            <td>
                                                {challanItem.challan_rate.value}
                                            </td>
                                            <td>
                                                ₹{challanItem.challan_quantity.value * challanItem.challan_rate.value}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="5">
                                    Total
                                </th>
                                <th>
                                    ₹ {this.getTotalAmount()}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </Card.Body>
        )
    }

    renderPrintButtonDiv = () => {
        return (
            <>
                <Row>
                    <Col className="text-center">
                        <button className="btn btn-primary" type="button" onClick={this.downloadForm}><FontAwesomeIcon icon={faPrint} /></button>
                    </Col>
                </Row>
                <hr /> 
            </>
        )
    }

    render() { 
        const {
            validated,
            loader,
            challanId
        } = this.state
        if(loader)
            return <Loader />
         else if(this.state.userData !== null && !this.state.userData.user.pchanged) {
          return <Redirect to={"/change-password"} />;
        }
        else
            return (
                <React.Fragment>
                    <Jumbotron className="jumbotron-title shadow-sm">
                        <h4 style={{ textAlign: "center" }}>
                            {
                                (challanId === null)?
                                "Add Challan"
                                :
                                "Edit Challan"
                            }
                        </h4>
                    </Jumbotron>
                    <Card className="main-container">
                        {this.renderPrintDiv()}
                        <Card.Body className="challanContainer" id="main-container-body-challan">
                            {
                                (challanId!==null)?
                                    this.renderPrintButtonDiv()
                                :
                                    null
                            }
                            <div className="form-container">
                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={this.handleSubmit}
                                >
                                    {this.renderGeneralForm()}
                                    {this.renderItemsArray()}
                                    {this.submitResetColumns()}
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </React.Fragment>
            );
    }
}
 
export default Challan;