import React, {
    useState,
    useReducer,
    useEffect,
    useContext,
} from "react";
import { UserContext } from "../contexts/userContext";
import application from "../constants/apiList";
import Loader from "./loader";
import swal from "@sweetalert/with-react";
import moment from 'moment'
import axios from "axios";
import { Col, Card, Jumbotron, Button, Form } from "react-bootstrap";
import "../styles/pages.scss";
import "../styles/componentsStyles/addJobsheet.scss";
import { Redirect } from 'react-router-dom';
var $ = require("jquery");

const initialState = {
    userid: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    pass: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    confPass: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    first_name: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    middle_name: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    last_name: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    adhar_pan: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    email: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    mobile_no: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    gst: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    franchise: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    company_title: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    company_add: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    company_info: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    privilege: {
        isValid: true,
        value: "",
        errorMsg: ""
    },
    authorise: {
        isValid: true,
        value: 0,
        errorMsg: ""
    },
}

function init(initialData) {
    return {
        data: initialData,
    };
}


const UserProfile = ({pageType}) => {
    const [userState, setUserState] = useContext(UserContext);
    const [allUsersData, setallUsersData] = useState([])
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [loaderState, setLoaderState] = useState(false)
    const [branchState, setBranchState] = useState([]);
    const [validated, setValidated] = useState(false);
    const [isInitDataLoadStarted, setIsInitDataLoadStarted] = useState(false);
    const [showNewBranchTextBox, setShowNewBranchTextBox] = useState(false);

    function reducer(state, action) {
        switch (action.type) {
            case "valueChanged":
                let stateData = {};
                let changedValue = {};
                changedValue["value"] = action.payload;
                changedValue["isValid"] = true;
                changedValue["errorMsg"] = "";
    
                state.data[action.key] = changedValue;
                stateData["data"] = state.data;
    
                return stateData;
    
            case "initialEditState":
                let initialEditState = {};
                initialEditState['data'] = action.payload;
                return initialEditState;
    
            default:
                throw new Error();
        }
    }

    const resetFormState = () => {
        for (let key in initialState) {
            let defaultValue = {};
            defaultValue['isValid'] = true;
            defaultValue['value'] = "";
            defaultValue['errorMsg'] = "";
            initialState[key] = defaultValue;
        }
        return initialState;
    }

    const resetForm = () => {
        resetFormState();
        dispatch({
            type: "initialEditState",
            key: '',
            payload: initialState,
        })
        setValidated(false);
    }

    useEffect(() => {
        if (userState.loggedInStatus && (pageType == "add-user" || pageType === "my-profile")) {
            setIsInitDataLoadStarted(true)
            setInitData();
            setLoaderState(true);
        }
    }, [pageType]);

    useEffect(()=>{
        //set new branch textbox to true if add new branch selected
        if(state.data.franchise.value === "Add New Branch")
        {
            //set value to empty
            dispatch({
                type: "valueChanged",
                key: "franchise",
                payload: "",
            });
            setShowNewBranchTextBox(true)
        }
        else
        {
            //if branch value already present then set textbox visibility to false
            let branchFoundIndex = branchState.findIndex((x) => x === state.data.franchise.value)
            if(branchFoundIndex !== -1)
                setShowNewBranchTextBox(false)
        }
    },[state])
    
    const setInitData = () => {
        if (window.location.href.includes("my-profile")) {
            getUserData();
        }
        else {
            resetForm()
            getAllUsersData();
        }
    }

    const getUserData = async () => {
        setLoaderState(true);
        try {
            const config = {
                url: application.API.APP_USERURL + "/" + userState.user.userName,
                type: "GET",
                withCredentials: true,
            };
            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                let userData = response.data.data;
                for (let key in userData) {
                    if(key !== "pass")
                    {
                        if (initialState.hasOwnProperty(key)) {
                            initialState[key]['value'] = (userData[key] === null) ? "" : userData[key];
                        }
                    }
                }
                getAllUsersData()
            }
            else {
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
            setLoaderState(false);
        }
        catch (e) {
            setLoaderState(false);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            console.log("Error", e)
        }
    }
    const getAllUsersData = async () => {
        setLoaderState(true);
        try {
            const config = {
                url: application.API.APP_USERURL,
                type: "GET",
                withCredentials: true,
            };
            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                setallUsersData(response.data.data);
                let newBranchState = ["Add New Branch"];
                response.data.data.map((user)=>{
                    if(newBranchState.findIndex((x)=>x === user.franchise) === -1)
                        newBranchState.push(user.franchise)
                })
                setBranchState(newBranchState)
            }
            else {
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
            setLoaderState(false);
        }
        catch (e) {
            setLoaderState(false);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            console.log("Error", e)
        }
    }

    const updateInsertUserData = async () => {
        setLoaderState(true);
        try {
            const userData = {};
            //set object keys value from the state
            Object.keys(state.data).map((stateDataKeyObj)=>{
                userData[stateDataKeyObj] = state.data[stateDataKeyObj].value
            })
            userData.authorise = userData.authorise === "" || userData.authorise === null ? 0 : userData.authorise
            let config = {}
            if(window.location.href.includes("my-profile"))
            {
                //remove empty keys for pass
                if(userData.pass === "" || userData.pass === null)
                {
                    delete userData.pass
                    delete userData.confPass
                }
                config = {
                    url: application.API.APP_USERURL+ "/" + userState.user.userName,
                    method: "patch",
                    withCredentials: true,
                    data: userData
                };
            }
            else
            {
                userData.creation_date = moment()
                config = {
                    url: application.API.APP_USERURL,
                    method: "post",
                    withCredentials: true,
                    data: userData
                };
            }
            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                let responseText = config.method === "post" ? "User added" : "User updated"
                swal({ title: "Success", text: responseText, icon: "success" }).then((value) => {
                    window.location.href = "/"
                  });;
            }
            else {
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
            setLoaderState(false);
        }
        catch(e){
            setLoaderState(false);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            console.log("Error", e)
        }
    }

    if (userState.loggedInStatus && !isInitDataLoadStarted) {
        setLoaderState(true);
        setIsInitDataLoadStarted(true)
        setInitData();
        setLoaderState(true);
    }


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity()) {
            event.preventDefault();
            updateInsertUserData()
        }
        else {
            event.preventDefault();
            event.stopPropagation();
            var errorElements = document.querySelectorAll(
                "input.form-control:invalid");
            $('html, body').animate({
                scrollTop: $(errorElements[0]).offset().top - 20
            }, 1000);
            swal({ title: "Error", text: "Please fix the errors in your form", icon: "warning" })
        }
    }

    if (loaderState)
        return <Loader />;
    if (!userState.user.pchanged)
      return <Redirect to={"/change-password"} />;
    return (
        <>
            <Jumbotron className="jumbotron-title shadow-sm">
                <h4 style={{ textAlign: "center" }}>Profile</h4>
            </Jumbotron>
            <Card className="main-container">
                <Card.Body className="main-container-body">
                    <div className="user-profile-container">
                        <div className="form-container">
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="userId">
                                        <Form.Label>User ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            disabled={userState?.user?.privilege === "regular user" || window.location.href.includes("my-profile")}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "userid",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.userid.value}
                                            placeholder="User ID"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="newPass">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "pass",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.pass.value}
                                            name="pass"
                                            autoComplete="current-password"
                                            placeholder="Password"
                                            required = {window.location.href.includes("add-user")}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="confirmPass">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            isInvalid={state.data.confPass.value !== state.data.pass.value}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "confPass",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.confPass.value}
                                            name="confpass"
                                            placeholder="Confirm Password"
                                            required={window.location.href.includes("add-user")}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="first_name">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "first_name",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.first_name.value}
                                            placeholder="First Name"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="middle_name">
                                        <Form.Label>Middle Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "middle_name",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.middle_name.value}
                                            placeholder="Middle Name"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="last_name">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "last_name",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.last_name.value}
                                            placeholder="Last Name"
                                            required
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="adhar_pan">
                                        <Form.Label>Adhar/Pan No.</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "adhar_pan",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.adhar_pan.value}
                                            placeholder="Adhar/Pan No."
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "email",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.email.value}
                                            placeholder="Email"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="mobile_no">
                                        <Form.Label>Mobile No.</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(event) => {
                                                const re = /^[0-9\b]+$/;
                                                if (event.target.value === '' || re.test(event.target.value)) {
                                                    dispatch({
                                                        type: "valueChanged",
                                                        key: "mobile_no",
                                                        payload: event.target.value,
                                                    });
                                                }
                                            }}
                                            value={state.data.mobile_no.value}
                                            placeholder="Mobile No."
                                            required
                                            maxLength="10"
                                            minLength="10"
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="franchise">
                                        <Form.Label>Branch</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "franchise",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.franchise.value}
                                            placeholder="Branch"
                                            required
                                        >
                                            {state.data.franchise.value === ""? (<option disabled value={""}>Select Branch</option>):null}
                                            {
                                                branchState.map((branch) => {
                                                    return (
                                                        <option key={branch + "branchOptions"} value={branch}>{branch}</option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                        {showNewBranchTextBox ?
                                            (
                                                <Form.Control
                                                    className="mt-3"
                                                    type="text"
                                                    rows={3}
                                                    onChange={(event) => {
                                                        dispatch({
                                                            type: "valueChanged",
                                                            key: "franchise",
                                                            payload: event.target.value,
                                                        });
                                                    }}
                                                    value={state.data.franchise.value}
                                                    placeholder="Enter new branch"
                                                />
                                            )
                                            :
                                            null
                                        }
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="gst">
                                        <Form.Label>GST No.</Form.Label>
                                        <Form.Control
                                            type="text"
                                            rows={3}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "gst",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.gst.value}
                                            placeholder="GST No."
                                            required
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="company_title">
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "company_title",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.company_title.value}
                                            placeholder="Company Name"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="company_add">
                                        <Form.Label>Company Address</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "company_add",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.company_add.value}
                                            placeholder="Company Address"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="company_info">
                                        <Form.Label>Company Contact Info</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "company_info",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.company_info.value}
                                            placeholder="Company Contact Info"
                                            required
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="authorise">
                                        <Form.Check
                                            label="Authorized"
                                            id="authorizedId"
                                            value={state.data.authorise.value}
                                            checked={state.data.authorise.value === 1 ? true : false}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "authorise",
                                                    payload: state.data.authorise.value === 1 ? 0 : 1,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                {}
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="privilege">
                                        <Form.Label>Privilege</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "valueChanged",
                                                    key: "privilege",
                                                    payload: event.target.value,
                                                });
                                            }}
                                            value={state.data.privilege.value}
                                            placeholder="Privilege"
                                            required
                                        >
                                            {state.data.privilege.value === ""?(<option disabled value="">Select Privilege</option>):null}
                                            <option value="admin">Admin</option>
                                            <option value="super admin">Super Admin</option>
                                            <option value="regular user">Regular User</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Button disabled={state.data.confPass.value !== state.data.pass.value} type="submit">Submit form</Button>
                                    <Button style={{ 'marginLeft': '45px' }} onClick={resetForm}>Reset form</Button>
                                </Form.Row>
                            </Form>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default UserProfile;